<template>
    <div>
        <b-modal id="modal-document" centered hide-footer no-close-on-backdrop no-close-on-esc size="lg"
            :body-class="'px-3 pt-2 overflow-preview-auto'" :footer-class="'d-block border-0 pt-0 pb-3 m-0'"
            :header-class="'align-items-center'" scrollable>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Document
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
                <b-dropdown id="dropdown-preview" class="load-dropdown mt-0" v-if="documentItem">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button :class="allowUpdateRecord ? 'mb-2':''" @click="downloadDoc(documentItem)">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Download</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button class='mb-2' v-if="fileName && allowUpdateRecord" @click="removeFile()">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="showPopup(mediaID)" v-if="allowUpdateRecord">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
                    </b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown id="dropdown-preview" class="load-dropdown mt-0" v-if="!documentItem && fileName">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button @click="removeFile()">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
                    </b-dropdown-item-button>
                </b-dropdown>
            </template>
            <div class="d-block text-center">
                <b-row class="mh-470px">
                    <b-col lg="4" md="8" sm="12">
                        <div class="text-left mb-3">
                            <label for="modal-datepicker" class="state">Date:</label>
                            <b-form-input id="modal-datepicker" class="" v-model="documentData.date"
                             :disabled="!allowUpdateRecord"
                                type="date"></b-form-input>
                            <p v-if="errors && errors.date" class="field-error">
                                {{ errors.date[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Description:</label>
                            <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                                v-model="documentData.description" :disabled="!allowUpdateRecord"></b-form-textarea>
                            <p v-if="errors && errors.description" class="field-error">
                                {{ errors.description[0] }}
                            </p>
                        </div>
                    </b-col>
                    <b-col lg="8" sm="12">
                        <div class="upload-btn-wrapper" v-if="!fileName">
                            <b-img :src="require('@/assets/drop.svg')"></b-img>
                            <button v-if="!fileName" class="foo">
                                Drop your document here, or browse
                            </button>
                            <button v-else class="foo">{{ fileName }}</button>

                            <input ref="docFile" type="file" name="docFile" v-on:change="handleFileUpload"
                                accept=".pdf, .png, .jpg, .jpeg" />
                            <p v-if="errors && errors.document" class="field-error">
                                {{ errors.document[0] }}
                            </p>
                        </div>
                        <div v-else>
                            <div class="review-media-section" :class="mediaType === 'pdf' ? 'overflow-hidden':''">
                                <iframe class="w-100 h-100 border-0" v-if="mediaType === 'pdf'" :src="media"></iframe>
                                <div v-else>
                                <div id="panzoom-element" ref="panzoomElement">
                                    <img :src="media" class="w-100 h-100 img-section" />
                                </div>
                            <div class="d-flex align-items-center justify-content-center zoom-section">
                                <b-icon @click="zoom(1)" icon="zoom-in" style="fill:white; cursor: pointer;"></b-icon>
                                <b-icon @click="zoom(-1)" class="ml-3" icon="zoom-out"
                                    style="fill:white;cursor: pointer;"></b-icon>
                            </div>
                            </div>
                        </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="text-right modal-footer border-0 p-0 mt-4">
                <b-button class="mt-3 btn cancel m-0" :class="hasDocChanged && allowUpdateRecord ? 'mr-3' : ''" block @click="resetPopup()">{{
                    documentItem ? 'Close' : 'Cancel' }}</b-button>
                <b-button class="mt-3 btn save mx-0 m-0" variant="primary" v-if="hasDocChanged && allowUpdateRecord"
                    @click="sendDocumentData">Save</b-button>
            </div>
        </b-modal>
        <DeleteModal v-if="showDeleteModal" @confirm="deleteItem($event)" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { checkValueExists } from '../../services/helper';
import DeleteModal from "@/components/DeleteModal.vue";
import Panzoom from '@panzoom/panzoom'
export default {
    name: "DotInspectionDocument",
    components: { DeleteModal },
    props: ['id', "documentItem", 'addDoc', 'updateDoc', 'allowVideo', 'driver', 'recordNo', 'mediaData', 'mediaDataID', 'mediaDataType', 'deleteDocItem','allowUpdateRecord'],
    data() {
        return {
            checkValueExists,
            fileName: null,
            media: null,
            mediaType: null,
            panzoom: null,
            showDeleteModal: false,
            defaultDocsData: {},
            mediaID: null,
            documentData: {
                date: new Date().toISOString().split('T')[0],
                document: null,
                description: null,
            },
            errors: {
                date: null,
                document: null,
                description: null,
            }
        }
    },
    computed: {
        ...mapGetters(['userCompany']),
        hasDocChanged() {
            return Object.keys(this.documentData).filter(field => {
                if (field !== 'updated_at') {
                    if (typeof this.documentData[field] === 'object') return JSON.stringify(this.documentData[field]) !== JSON.stringify(this.defaultDocsData[field])
                    if (field === 'description') {
                        return this.checkValueExists(this.documentData[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n')) != this.checkValueExists(this.defaultDocsData[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n'));
                    }
                    else if (!this.documentData[field] && !this.defaultDocsData[field]) return false
                    else return this.checkValueExists(this.documentData[field]) != this.checkValueExists(this.defaultDocsData[field])
                } return false
            }).length > 0
        },
    },
    watch: {
        documentItem() {
            if (this.documentItem) {
                console.log(this.documentItem)
                this.documentData.description = this.documentItem.description
                this.documentData.date = this.documentItem.date
                if (this.documentItem.document) {
                    this.fileName = this.documentItem.document.substring(this.documentItem.document.lastIndexOf('/') + 1, this.documentItem.document.indexOf('?'));
                    this.documentData.document = this.documentItem.document
                } else {
                    this.documentData.document = null;
                }
                this.defaultDocsData = JSON.parse(JSON.stringify(this.documentData))
                this.media = this.mediaData
                this.mediaID = this.mediaDataID
                this.mediaType = this.mediaDataType
            }
        }
    },
    methods: {
        ...mapActions(['setLoader', 'setNotification']),
        zoom(level) {
            if (!this.panzoom) {
                this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
                    maxScale: 5
                })
            }
            level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
        },
        resetPopup() {
            this.documentData = {
                date: new Date().toISOString().split('T')[0],
                document: null,
                description: null,
            };
            this.defaultDocsData = {}
            this.showDeleteModal = false
            this.errors = {
                date: null,
                document: null,
                description: null,
            };
            this.fileName = null
            this.$emit("popupClose")
            this.panzoom = null
            this.$bvModal.hide("modal-document");

        },
        deleteItem(status) {
            if (status) {
                this.deleteDocItem(status, this.itemId)
                this.$bvModal.hide("modal-document");
            }
            else this.showDeleteModal = false
        },
        handleFileUpload() {
            this.documentData.document = this.$refs.docFile.files[0];
            this.fileName = this.documentData.document.name;
            this.media = URL.createObjectURL(this.documentData.document);
            if (this.$refs.docFile.files[0].type.includes('pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
        },
        removeFile() {
            this.fileName = null;
            this.documentData.document = null
            this.media = null
            this.mediaType = null
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        showPopup(id) {
            this.showDeleteModal = true;
            this.itemId = id;
        },
        downloadDoc(data) {
            this.setLoader(true)
            fetch(data.document)
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${data.name}.${data.document.split('.').pop().split(/[#?]/)[0]}`
                    link.click();
                    link.remove()
                    this.setLoader(false)
                })
                .catch(this.setLoader(false));
        },
        async sendDocumentData() {
            try {
                const nullKeys = this.checkNullValues({
                    date: this.documentData.date,
                    document: this.documentData.document
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    let method = 'Added'
                    let formData = new FormData();
                    let fileType = this.documentData.document instanceof File || this.documentData.document instanceof Blob;
                    if (!fileType) this.documentData.document = null
                    formData.append("date", this.documentData.date);
                    formData.append("description", this.documentData.description);
                    if (this.documentData.document)
                        formData.append(
                            "document",
                            new File(
                                [this.documentData.document],
                                this.recordNo + "_" + this.driver +
                                "_" + this.userCompany + "_" +
                                new Date().getTime() +
                                "." +
                                this.documentData.document["type"].split("/").pop(),
                                { type: this.documentData.document["type"] }
                            )
                        )
                    if (this.documentItem) {
                        await this.updateDoc({ id: this.id, docID: this.documentItem.id, payload: formData })
                        method = 'Updated'
                    }
                    else await this.addDoc({ id: this.id, payload: formData })
                    await this.setNotification({
                        msg: `Document Successfully ${method}`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.$emit("documentsUpdated", true)
                    this.resetPopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    console.log(nullKeys)
                    throw error;
                }
            }
            catch (error) {
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                }
            }
        }
    },
}
</script>